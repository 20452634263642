import { TextFieldElement } from "react-hook-form-mui";

import { OnboardGridItem } from "../OnboardGridItem";

export default function UserPassLogin() {
  return (
    <>
      <OnboardGridItem>
        <TextFieldElement
          label="Admin Username"
          type={"text"}
          name={"username"}
          required
        />
      </OnboardGridItem>
      <OnboardGridItem>
        <TextFieldElement
          label="Admin Password"
          type={"password"}
          name={"password"}
          required
        />
      </OnboardGridItem>
    </>
  );
}
