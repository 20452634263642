import { useContext, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import {
  Avatar,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";

import * as amplitude from "@amplitude/analytics-browser";
import * as Sentry from "@sentry/react";

import { PermissionsContext } from "../PermissionsContext";
import axios from "../axios";
import {
  ButtonExitLink,
  ExitButtonApprovePageStyles,
  LinkPage,
} from "../components/ButtonExitLink";
import { ButtonWithText } from "../components/ButtonWithText";
import { checkValid } from "../utils/checkValid";
import { jwtDecode } from "jwt-decode";
import { LinkToken } from "../types/LinkToken";

export default function Approve() {
  const navigate = useNavigate();
  let { tsp } = useParams();
  const { search } = useLocation();

  // Link token bearing serviceID and fleetCode claims for authorizing requests to Mercury
  const urlToken = new URLSearchParams(search).get("token");

  // If 'true', will redirect to dev dashboard on success instead of the service RedirectURI
  const urlMagicLink = new URLSearchParams(search).get("magicLink");

  const {
    serviceName,
    setApproved,
    setMagicLink,
    setMotiveClientID,
    setSamsaraClientID,
    setZubieClientID,
    setServiceName,
    setToken,
    setTSP,
    setUpdatingTSP,
    setExistingTSPs,
    setRedirectURISuccess,
    setRedirectURIError,
    redirectURIError,
    token,
  } = useContext(PermissionsContext);

  useEffect(() => {
    if (!checkValid(urlToken)) {
      navigate("/error", {
        state: {
          errorMsg: "Onboarding link is invalid.",
          errorCaption: "Please try to onboard again.",
        },
      });
    } else {
      setToken(urlToken);
      setMagicLink(urlMagicLink);
      const decodedToken = jwtDecode<LinkToken>(urlToken!.toString());
      amplitude.setUserId("service-" + String(decodedToken.sid));
      amplitude.setDeviceId("fleet-code-" + decodedToken.fc);
    }
  });

  useEffect(() => {
    if (tsp) {
      setTSP(tsp);
    }
    axios
      .get("/fleet/link", {
        headers: { Authorization: "Bearer " + urlToken },
      })
      .then((res) => {
        if (res.status === 200) {
          setServiceName(res.data["service_name"]);
          setRedirectURISuccess(res.data["redirect_uri_success"]);
          setRedirectURIError(res.data["redirect_uri_error"]);
          setMotiveClientID(res.data["motive_client_id"]);
          setSamsaraClientID(res.data["samsara_client_id"]);
          setZubieClientID(res.data["zubie_client_id"]);
          Sentry.setUser({ service_name: res.data["service_name"] });

          if (res.data["fleet_exists"]) {
            setExistingTSPs(res.data["existing_tsps"]);
            navigate("/updateOrAdd");
          }
        }
      })
      .catch((err) => {
        var errorMsg = "Onboarding link is invalid.";
        if (err && err.message.includes("expire"))
          errorMsg = "Onboarding link has expired.";

        navigate("/error", {
          state: {
            errorMsg: errorMsg,
            errorCaption: "Please try to onboard again.",
          },
        });
      });
  }, [
    navigate,
    setMotiveClientID,
    setSamsaraClientID,
    setZubieClientID,
    setServiceName,
    setTSP,
    setExistingTSPs,
    setUpdatingTSP,
    setRedirectURISuccess,
    setRedirectURIError,
    tsp,
    urlToken,
  ]);

  const onApprove = () => {
    setApproved(true);
    amplitude.track("User Began Onboarding", {
      tsp: tsp,
      serviceName: serviceName,
    });

    if (tsp) {
      navigate({
        pathname: "/onboard",
        search: search,
      });
    } else {
      navigate({
        pathname: "/selectTSP",
        search: search,
      });
    }
  };

  if (serviceName === "") return <CircularProgress sx={{ color: red[500] }} />;

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      {redirectURIError ? (
        <ButtonExitLink
          token={token}
          redirectURIError={redirectURIError}
          serviceName={serviceName}
          pageOnExit={LinkPage.Approve}
          styles={ExitButtonApprovePageStyles}
        />
      ) : null}
      <Grid item>
        <Typography variant="h5" align="center">
          <b>{serviceName}</b> uses <b>Axle</b> to connect to your telematics
          data.
        </Typography>
      </Grid>
      <Grid item>
        <List>
          <ListItem>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: red[100] }}>
                <ShareLocationIcon sx={{ color: red[500] }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Simplify sharing data"
              secondary="Axle helps you easily share all your relevant telematics data."
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: red[100] }}>
                <VerifiedUserIcon sx={{ color: red[500] }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Built-in privacy and security"
              secondary="All your data is shared securely and only with your permission."
            />
          </ListItem>
        </List>
      </Grid>
      <Grid item width={"100%"} justifyContent="center">
        <ButtonWithText variant="contained" onClick={onApprove}>
          Continue
        </ButtonWithText>
      </Grid>
      <Grid item>
        <Divider variant="middle" sx={{ margin: "15px 0 5px 0" }} />
        <Typography display="block" variant="caption" align={"center"}>
          By selecting "Continue" you agree to the{" "}
          <a
            href="https://www.axleapi.com/terms-of-use"
            target={"_blank"}
            rel="noreferrer"
            color={red[500]}
          >
            Terms of Use
          </a>{" "}
          and{" "}
          <a
            href="https://www.axleapi.com/privacy"
            target={"_blank"}
            rel="noreferrer"
            color={red[500]}
          >
            Privacy Policy
          </a>
          .
        </Typography>
      </Grid>
    </Grid>
  );
}
