import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import * as amplitude from "@amplitude/analytics-browser";
import * as Sentry from "@sentry/react";

import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

if (process.env.REACT_APP_NODE_ENV === "prod") {
  console.log = () => {};
}

if (process.env.REACT_APP_NODE_ENV === "prod") {
  amplitude.init("f83054aab0ef782fa13bb943ec5dad87", {
    defaultTracking: true,
  }); // Production
} else if (process.env.REACT_APP_NODE_ENV === "staging") {
  amplitude.init("78515dccd05d7e89dd0b2e0af18e39d0", {
    defaultTracking: true,
  }); // Staging
} else {
  amplitude.init("264c2be025d563af6d8b0d167ec6ff32", {
    defaultTracking: true,
  }); // Local
}

if (
  process.env.REACT_APP_NODE_ENV === "prod" ||
  process.env.REACT_APP_NODE_ENV === "staging"
) {
  Sentry.init({
    dsn: "https://6e93758803084060afd41e9904d98472@o4504101533712384.ingest.sentry.io/4504854563520512",
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: true,
      }),
    ],
    ignoreErrors: ["window.nativeAppNavTo is not a function"],
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.REACT_APP_NODE_ENV,
  });
}

const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(null);
