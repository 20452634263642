import { TextFieldElement } from "react-hook-form-mui";

import { OnboardGridItem } from "../OnboardGridItem";
import UserPassLogin from "./UserPass";

export default function WebfleetLogin() {
  return (
    <>
      <UserPassLogin />
      <OnboardGridItem>
        <TextFieldElement
          label="Account Name"
          type={"text"}
          name={"database"}
        />
      </OnboardGridItem>
    </>
  );
}
