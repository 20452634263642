import React from "react";

import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Box, Button } from "@mui/material";

type HelpButtonOptionProps = {
  text: string;
  onClick: () => void;
  disabled?: boolean;
};

export const HelpButtonOption: React.FC<HelpButtonOptionProps> = ({
  text,
  onClick,
}) => (
  <Button
    onClick={onClick}
    sx={{
      justifyContent: "left",
      textTransform: "none",
      border: "0.5px solid",
      borderColor: "divider",
      overflow: "hidden",
      "&:hover": {
        backgroundColor: "action.hover",
      },
    }}
  >
    <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
      <ArrowRightIcon sx={{ mr: 1 }} />
      {text}
    </Box>
  </Button>
);
