import React from "react";

import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Button, SxProps, Theme } from "@mui/material";
import { red } from "@mui/material/colors";
import axios from "../axios";

export enum LinkPage {
  Approve = "Approve",
  SelectTSP = "Select TSP",
  OnboardingCreds = "Onboarding Credentials",
  Motive = "Motive Fail",
}

type BaseButtonExitLinkProps = {
  token: string;
  redirectURIError: string;
  serviceName: string;
  pageOnExit: LinkPage;
  selectedTSP?: string;
};

type IconButtonExitLinkProps = BaseButtonExitLinkProps & {
  styles?: SxProps<Theme> | undefined;
};

export const ButtonExitLink: React.FC<IconButtonExitLinkProps> = ({
  token,
  redirectURIError,
  serviceName,
  pageOnExit,
  selectedTSP,
  styles,
}) => {
  return (
    <Button
      sx={{
        minWidth: "40px",
        color: red[500],
        "&:hover": {
          backgroundColor: red[100],
        },
        ...styles,
      }}
      onClick={() =>
        handleLeaveLink({
          token,
          redirectURIError,
          serviceName,
          pageOnExit,
          selectedTSP,
        })
      }
    >
      <ExitToAppIcon />
    </Button>
  );
};

export function handleLeaveLink({
  token,
  redirectURIError,
  serviceName,
  pageOnExit,
  selectedTSP,
}: BaseButtonExitLinkProps) {
  if (
    redirectURIError &&
    (process.env.REACT_APP_NODE_ENV === "dev" ||
      (process.env.REACT_APP_NODE_ENV === "prod" && serviceName === "AtoB"))
  ) {
    axios
      .post(
        "/service/onboard/abandoned",
        {
          page_on_unload: pageOnExit,
          selected_tsp: selectedTSP,
        },
        {
          headers: { Authorization: token ? "Bearer " + token : "" },
        },
      )
      .then(() => (window.location.href = redirectURIError))
      .catch((error) => {
        console.log(
          "An error happened while triggering error callback.",
          error,
        );
        // TODO: show toast?
        setTimeout(() => {
          window.location.href = redirectURIError;
        }, 3000);
      });
  }
}

export const ExitButtonTSPPageStyles = {
  position: "absolute",
  right: "0",
  top: "50%",
  transform: "translate(0, -50%)",
};

export const ExitButtonApprovePageStyles = {
  position: "absolute",
  top: 40,
  right: 20,
};
