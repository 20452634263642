import { TextFieldElement } from "react-hook-form-mui";

import { OnboardGridItem } from "../OnboardGridItem";

export default function APIKeyLogin() {
  return (
    <OnboardGridItem>
      <TextFieldElement
        label="API Key"
        type={"text"}
        name={"apikey"}
        required
      />
    </OnboardGridItem>
  );
}
