import { TextFieldElement } from "react-hook-form-mui";

import { OnboardGridItem } from "../OnboardGridItem";

export default function FleetUpLogin() {
  return (
    <>
      <OnboardGridItem>
        <TextFieldElement
          label="Account ID"
          type={"text"}
          name={"authorizationcode"}
          required
        />
      </OnboardGridItem>
      <OnboardGridItem>
        <TextFieldElement
          label="App Secret"
          type={"text"}
          name={"database"}
          required
        />
      </OnboardGridItem>
      <OnboardGridItem>
        <TextFieldElement
          label="API Key"
          type={"text"}
          name={"apikey"}
          required
        />
      </OnboardGridItem>
    </>
  );
}
