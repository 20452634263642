import { Grid } from "@mui/material";

export default function CancelRequest() {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      You have cancelled the request. You may now close this page.
    </Grid>
  );
}
