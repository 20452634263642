import { TextFieldElement } from "react-hook-form-mui";

import { OnboardGridItem } from "../OnboardGridItem";
import UserPassLogin from "./UserPass";

export default function FleetCompleteLogin() {
  return (
    <>
      <UserPassLogin />
      <OnboardGridItem>
        <TextFieldElement
          label="Client ID"
          type={"text"}
          name={"database"}
          required
        />
      </OnboardGridItem>
      <OnboardGridItem>
        <TextFieldElement
          label="Fleet Name (optional)"
          type={"text"}
          name={"carriername"}
        />
      </OnboardGridItem>
      <OnboardGridItem>
        <TextFieldElement
          label="DOT Number (optional)"
          type={"number"}
          name={"dotnumber"}
        />
      </OnboardGridItem>
    </>
  );
}
