import { createContext, useMemo, useState } from 'react';

const PermissionsContext = createContext();

const PermissionsProvider = (props) => {
  const [token, setToken] = useState('');

  // Service information
  const [serviceName, setServiceName] = useState('');
  const [hcssClientID, setHCSSClientID] = useState('');
  const [motiveClientID, setMotiveClientID] = useState('');
  const [samsaraClientID, setSamsaraClientID] = useState('');
  const [zubieClientID, setZubieClientID] = useState('');

  // Existing link?
  const [updatingTSP, setUpdatingTSP] = useState(false);
  const [existingTSPs, setExistingTSPs] = useState([]);
  const [TSP, setTSP] = useState('');

  const [approved, setApproved] = useState(false);
  const [onboarded, setOnboarded] = useState(false);

  // Redirect
  const [magicLink, setMagicLink] = useState(false);
  const [redirectURI, setRedirectURI] = useState('');
  const [redirectURISuccess, setRedirectURISuccess] = useState('');
  const [redirectURIError, setRedirectURIError] = useState('');

  // the state that we'll be storing the Permissions into

  /* because we will be providing an object to the provider, it is better to put the value inside a useMemo so that the component will only re-render when there's a change in the value. */

  const value = useMemo(
    () => ({
      approved,
      hcssClientID,
      magicLink,
      motiveClientID,
      onboarded,
      redirectURI,
      redirectURISuccess,
      redirectURIError,
      samsaraClientID,
      zubieClientID,
      serviceName,
      token,
      TSP,
      existingTSPs,
      updatingTSP,

      setApproved,
      setHCSSClientID,
      setMagicLink,
      setMotiveClientID,
      setOnboarded,
      setRedirectURI,
      setRedirectURISuccess,
      setRedirectURIError,
      setSamsaraClientID,
      setZubieClientID,
      setServiceName,
      setToken,
      setTSP,
      setExistingTSPs,
      setUpdatingTSP,
    }),
    [
      approved,
      magicLink,
      hcssClientID,
      motiveClientID,
      onboarded,
      redirectURI,
      redirectURISuccess,
      redirectURIError,
      samsaraClientID,
      zubieClientID,
      serviceName,
      token,
      TSP,
      existingTSPs,
      updatingTSP,
    ]
  );

  return (
    <PermissionsContext.Provider value={value}>
      {props.children}
    </PermissionsContext.Provider>
  );
};
export { PermissionsContext, PermissionsProvider };
