import { Route, Routes } from "react-router-dom";

import { Grid, Paper, Stack } from "@mui/material";

import "./App.css";
import { PermissionsProvider } from "./PermissionsContext";
import Footer from "./components/Footer";
import { HelpButton } from "./components/HelpButton";
import Approve from "./pages/Approve";
import CancelRequest from "./pages/CancelRequest";
import Confirmation from "./pages/Confirmation";
import ErrorPage from "./pages/ErrorPage";
import MotiveRedirect from "./pages/Motive";
import Onboard from "./pages/Onboard";
import SamsaraRedirect from "./pages/Samsara";
import SelectTSP from "./pages/SelectTSP";
import UpdatePermissions from "./pages/UpdatePermissions";
import ZubieRedirect from "./pages/Zubie";

function App() {
  return (
    <PermissionsProvider>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          minWidth: "100%",
          minHeight: "100vh",
          display: "flex",
          background: "#fafafa",
        }}
      >
        <Grid
          item
          xs={11}
          md={3}
          sx={{
            position: "relative",
            background: "#fff",
            minHeight: "70vh",
          }}
        >
          <Paper
            component={Stack}
            direction="column"
            justifyContent="center"
            elevation={8}
            sx={{
              position: "relative",
              background: "#fff",
              height: "70vh",
              padding: "25px",
            }}
          >
            <HelpButton />
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              maxWidth={"100%"}
            >
              <Routes>
                <Route path="/" element={<Approve />} />
                <Route path="/selectTSP" element={<SelectTSP />} />
                <Route path="/login/:tsp" element={<Approve />} />
                <Route path="/onboard" element={<Onboard />} />
                <Route path="/updateOrAdd" element={<UpdatePermissions />} />
                <Route path="/confirmation" element={<Confirmation />} />
                <Route path="/cancel" element={<CancelRequest />} />
                <Route path="/motive" element={<MotiveRedirect />} />
                <Route path="/samsara" element={<SamsaraRedirect />} />
                <Route path="/zubie" element={<ZubieRedirect />} />
                <Route path="/error" element={<ErrorPage />} />
              </Routes>
            </Grid>
          </Paper>
        </Grid>
        <Footer />
      </Grid>
    </PermissionsProvider>
  );
}

export default App;
