import { Box, Grid } from "@mui/material";

import Logo from "../images/logo/axle logo.png";

export default function Footer() {
  return (
    <footer
      style={{ color: "gray", padding: 30, position: "fixed", bottom: 0 }}
    >
      <Grid
        container
        item
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Grid item sx={{ padding: 0 }}>
          <div>Powered by </div>
        </Grid>
        <Grid item sx={{ padding: 0 }}>
          <a target="_blank" href="//www.axleapi.com" rel="noopener noreferrer">
            <Box
              component="img"
              sx={{
                height: "3vh",
              }}
              alt="Axle"
              src={Logo}
            />
          </a>
        </Grid>
      </Grid>
    </footer>
  );
}
