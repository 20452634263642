import Button, { ButtonProps } from "@mui/material/Button";
import { red } from "@mui/material/colors";
import { styled } from "@mui/material/styles";

export const ButtonWithText = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText(red[500]),
  backgroundColor: red[500],
  "&:hover": {
    backgroundColor: red[700],
  },
  padding: "15px",
  width: "100%",
  margin: "0 auto",
  display: "flex",
}));
