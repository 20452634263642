import { TextFieldElement } from "react-hook-form-mui";

import { OnboardGridItem } from "../OnboardGridItem";
import UserPassLogin from "./UserPass";

export default function DriverTechLogin() {
  return (
    <>
      <UserPassLogin />
      <OnboardGridItem>
        <TextFieldElement label="Data Store" type={"text"} name={"database"} />
      </OnboardGridItem>
      <OnboardGridItem>
        <TextFieldElement
          label="Company Code"
          type={"text"}
          name={"authorizationcode"}
        />
      </OnboardGridItem>
    </>
  );
}
