import { TextFieldElement } from "react-hook-form-mui";

import { OnboardGridItem } from "../OnboardGridItem";

export default function TruckXLogin() {
  return (
    <>
      <OnboardGridItem>
        <TextFieldElement
          label="DOT Number"
          type={"number"}
          name={"dotnumber"}
        />
      </OnboardGridItem>
    </>
  );
}
